<template>
  <div class="box">
    <cui-title :title="title" :showBack="showBack"></cui-title>
    <router-view class="content"></router-view>
  </div>
</template>

<script>
export default {
  name: "autoRouterParent",
  data() {
    return {
      title: "",
      showBack: true,
    };
  },
  watch: {
    $route: function (value) {
      this.title = value.meta.title;
    },
  },
  created() {
    this.title = this.$route.meta.title;
  },
  methods: {
    hiddenBack() {
      this.showBack = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>